/**
 * App
*/

// import $ from "jquery";
import AOS from "../../node_modules/aos/dist/aos";


document.addEventListener('DOMContentLoaded', function() {
    AOS.init({
        offset: -210,
    });
});